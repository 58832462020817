<template>
  <div class="search-result-box" @click="GoToFile">
    <div class="search-result-author">
      Author: {{ itm.author || 'Unknown' }} - Created at {{ itm.createdDate
      }}<span title="Project" v-if="itm.year">
        - Project: {{ itm.project }}</span
      >
    </div>
    <!-- <div
      v-if="!itm.private"
      class="search-result-download"
      title="Download"
      @click="DownloadFile(itm)"
    >
      Download
    </div> -->
    <div class="search-result-title">{{ itm.no }}. {{ itm.name }}</div>
    <div class="search-result-url">{{ itm.webUrl }}</div>
    <!-- <div class="search-result-project" v-if="itm.year"><span title="Year">{{ itm.year }}</span> - <span title="Project">{{ itm.project }}</span></div> -->
    <div class="search-result-summary" v-if="itm.summary">
      {{ itm.summary }}
    </div>
  </div>
</template>

<script>
// import Services from '../services/index.vue'

export default {
  name: 'SearchResults',
  props: ['itm'],
  methods: {
    GoToFile() {
      window.open(this.itm.webUrl)
    }
    // async DownloadFile({ id, driveId }) {
    //   console.log('feature disabled.')
    //   // const { data } = await Services.Download(id, driveId)
    //   // location.replace(data.path)
    // }
  }
}
</script>

<style scoped>
.search-result-box {
  animation: 0.5s appearAndMove2;
  text-align: left;
  margin: 10px 0;
  padding: 5px;
  background-color: rgba(255, 255, 255, 0.7);
  border-radius: 2px;
  cursor: pointer;
  border:  1px solid #666;
  border-radius: 6px;
}
.search-result-box:hover {
  background-color: rgba(230, 230, 230, 0.9);
  /* cursor: pointer; */
}
.search-result-title {
  font-size: 12pt !important;
  font-weight: bold;
  color: #666;
}
.search-result-url {
  font-size: 10pt !important;
  font-weight: bold;
  color: #666;
  margin-bottom: 10px;
}
.search-result-author {
  font-size: 10pt !important;
  color: #666;
  font-weight: bold;
  position: relative;
  display: inline-block;
}
.search-result-project {
  font-size: 10pt !important;
  color: #666;
  font-weight: bold;
}
.search-result-summary {
  font-size: 8pt !important;
  color: #666;
  font-weight: bold;
}
.search-result-download {
  position: relative;
  display: block;
  float: right;
  color: #666;
  font-weight: bold;
  background-color: darkcyan;
  padding: 2px 6px;
  border-radius: 3px;
}
.search-result-download:hover {
  cursor: pointer;
  color: #666;
  background-color: green;
}
@keyframes appearAndMove2 {
  0% {
    opacity: 0;
    margin-top: -10px;
  }
  100% {
    opacity: 1;
    margin-top: 0px;
  }
}
</style>
