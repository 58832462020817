<template>
  <div>
    <span
      id="statsPopover"
      class="welcome-msg"
      :class="statsPop ? 'active' : ''"
      v-if="hasUser"
      @click="getStats()"
      >{{ data.account.name }}</span
    >
    <b-popover
      target="statsPopover"
      :show.sync="statsPop"
      triggers=""
      placement="left"
      custom-class="stats-popover"
    >
      <ul class="stats-list">
        <li>
          <font-awesome-icon icon="fa-solid fa-magnifying-glass" />
          {{ statsData.queries }}
        </li>
        <li>
          <font-awesome-icon icon="fa-solid fa-download" />
          {{ statsData.downloads }}
        </li>
      </ul>
    </b-popover>

    <b-button
      :variant="btnStyle"
      class="auth-btn"
      size="sm"
      v-if="!btnDisabled"
      @click="exec()"
      >{{ btnTxt }}</b-button
    >
    <b-button
      variant="outline-secondary"
      class="auth-btn"
      size="sm"
      disabled
      v-if="btnDisabled"
      >Please wait...</b-button
    >
  </div>
</template>

<style scoped>
.auth-btn {
  border: 2px solid #fff;
  color: #fff;
  line-height: 20px;
}
.welcome-msg {
  font-size: 10pt;
  font-weight: bold;
  color: #ccc;
  margin: 0 10px 0 0;
  cursor: pointer;
}
.welcome-msg.active {
  color: #fff;
}
.stats-list {
  margin: 0;
  padding: 0;
  list-style: none;
}

.stats-list li {
  font-size: 10pt;
  font-weight: bold;
  display: inline-block;
  margin: 0 5px;
}

.stats-popover {
  background-color: rgb(255, 255, 255) !important;
  background: linear-gradient(
    to left,
    rgba(255, 255, 255, 1),
    rgba(200, 200, 200, 0.3)
  );
}
</style>

<script>
import * as msal from '@azure/msal-browser'
import VueCookies from 'vue-cookies'
import Services from '../services/index.vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

const msalConfig = {
  auth: {
    clientId: process.env.VUE_APP_AZURE_CLIENT_ID,
    authority: process.env.VUE_APP_AZURE_AUTHORITY,
    redirectUri: process.env.VUE_APP_AZURE_REDIRECT_URL
  },
  cache: {
    cacheLocation: 'sessionStorage',
    storeAuthStateInCookie: true
  }
}
const msalClient = new msal.PublicClientApplication(msalConfig)
const InitClient = async function() {
  try {
    const accounts = msalClient.getAllAccounts()
    if (accounts.length > 0) {
      const silentRequest = {
        account: accounts[0],
        scopes: process.env.VUE_APP_AZURE_SIGNIN_SCOPES.split(',')
      }
      const { accessToken, account } = await msalClient.acquireTokenSilent(
        silentRequest
      )
      return {
        accessToken,
        account: {
          name: account.name,
          username: account.username
        }
      }
    } else {
      return null
    }
  } catch (Exception) {
    console.log({ Error: Exception.message })
    return null
  }
}

export default {
  name: 'Azure',
  props: ['theme'],
  components: {
    FontAwesomeIcon
  },
  data() {
    return {
      btnStyle: 'outline-info',
      btnTxt: 'Sign-in',
      btnDisabled: false,
      hasUser: false,
      statsPop: false,
      statsData: {
        queries: 0,
        downloads: 0
      },
      data: {}
    }
  },
  async mounted() {
    this.Init()
    this.btnTxt = this.hasUser ? 'Sign-out' : 'Sign-in'
    this.btnStyle = this.theme === 'top' ? 'info' : 'outline-info'
  },
  watch: {
    theme() {
      this.btnStyle = this.theme === 'top' ? 'info' : 'outline-info'
    },
    hasUser(status) {
      this.btnTxt = status ? 'Sign-out' : 'Sign-in'
      // console.log('tick')
      console.log(
        'user %s %s.',
        status?.name ?? null,
        status ? 'signed in' : 'signed out'
      )
      if (status) {
        VueCookies.set('__user-fullname', status.name)
      } else {
        VueCookies.remove('__user-fullname')
      }
      this.$root.$emit('hasUser', status)
    }
  },
  methods: {
    async Init() {
      this.data = await InitClient()
      this.hasUser = this.data?.account ?? false
      this.btnDisabled = false
      if (!this.hasUser && location.pathname !== '/') {
        location.replace('/')
      }
      // Handle 401!
      Services.Request.interceptors.response.use(
        (response) => response,
        (error) => {
          if (error.response.status === 401) {
            if (!this.signinLock) {
              console.info('SIGN-IN AGAIN PLEASE!')
              this.ResetCookies()
              this.SignIn()
            }
          } else {
            console.log('ERR:%d', error.response.status, error)
            return Promise.reject(error)
          }
        }
      )
    },
    exec() {
      if (!this.hasUser && !this.btnDisabled) {
        return this.SignIn()
      }
      return this.SignOut()
    },
    async SignIn() {
      try {
        const msalRequest = {
          scopes: process.env.VUE_APP_AZURE_SIGNIN_SCOPES.split(',')
        }
        this.btnDisabled = true
        const { accessToken } = await msalClient.loginPopup(msalRequest)
        VueCookies.set('__access-token', accessToken)
        await Services.Auth()
        this.Init()
      } catch ({ message }) {
        console.log({ Error: message })
        this.ResetCookies()
        this.Init()
      }
    },
    async SignOut() {
      try {
        this.btnDisabled = true
        const logoutRequest = {
          account: msalClient.getAccountByUsername(this.data.account.username)
        }
        await msalClient.logoutPopup(logoutRequest)
        this.ResetCookies()
        this.Init()
      } catch (Exception) {
        let { message } = Exception
        console.log({ Error: message })
        this.Init()
      }
    },
    async getStats() {
      if (!this.statsPop) {
        const { data, status } = await Services.Auth()
        if (status === 200) {
          this.statsData = data.stats
        }
      }
      this.statsPop = !this.statsPop
    },
    ResetCookies() {
      VueCookies.remove('__access-token')
      VueCookies.remove('__user-fullname')
    }
  }
}
</script>
