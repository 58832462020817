<template>
  <div>
    <a href="https://candesic.com" target="_blank">
      <img src="../assets/candesic.png" alt="" class="Logo" />
      <div class="logo-info">Knowledge Management System</div>
    </a>
    <div class="app-info">
      <pre v-if="!isPrd()">
        <b-badge title="" variant="warning" class="env-badge">{{
          env
        }} v{{appVersion}}</b-badge></pre>
      <pre v-if="isPrd()">
          <b-badge title="" variant="success" class="env-badge">{{appName}} v{{appVersion}}</b-badge>
        </pre>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Logo',
  data() {
    return {
      env:
        this?.$store?.getters?.appEnv ??
        process?.env?.VUE_APP_ENV ??
        'production'
    }
  },
  methods: {
    isPrd() {
      return ['staging', 'development'].indexOf(this.env) === -1
    }
  },
  computed: {
    appName() {
      return this?.$store?.getters?.appName ?? 'default'
    },
    appVersion() {
      return this?.$store?.getters?.appVersion ?? '0.0.0'
    }
  }
}
</script>

<style>
.Logo {
  max-width: 14%;
  padding: 3px 12px 3px 8px;
  margin: 0 5px 0 0;
  background-color: rgba(255, 255, 255, 0.8) !important;
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 1),
    rgba(245, 245, 245, 0.1)
  );
  border-radius: 4px;
}

.logo-info {
  display: inline-block;
  font-family: 'Quicksand', 'Source Sans Pro', -apple-system, BlinkMacSystemFont,
    'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
  color: #fff;
  letter-spacing: 1px;
  vertical-align: bottom;
  line-height: 30px;
  margin-bottom: 2px !important;
  font-weight: 500;
}

.app-info {
  display: none;
  position: relative;
  text-align: left !important;
  padding: 0 !important;
  margin: 0 0 0 220px !important; 
}
.env-badge {
  text-transform: uppercase;
  font-weight: bold;
  font-size: 10px;
  letter-spacing: 0.1em;
  cursor: default;
}

@keyframes appear {
  0% {
    opacity: 0;
  }
}
</style>
