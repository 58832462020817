<template>
  <div class="bg-layer">
    <div id="app" class="container">
      <p v-if="!connected">
        <br />
        <Logo />
        <br />
        <center>Service connection failed.</center>
      </p>
      <router-view v-if="connected" />
    </div>
  </div>
</template>

<script>
import Logo from './components/Logo.vue'
import Services from './services/index.vue'

export default {
  name: 'App',
  components: {
    Logo
  },
  data() {
    return {
      connected: false
    }
  },
  async mounted() {
    try {
      const { status } = await Services.ServicePing()
      this.connected = status === 200
    } catch ({ message }) {
      console.log({ message })
    }
  }
}
</script>

<style>
body {
  min-height: 100vh !important;
  font-family: 'Source Sans Pro', -apple-system, BlinkMacSystemFont, 'Segoe UI',
    Roboto, 'Helvetica Neue', Arial, sans-serif;
  font-size: 16px;
  word-spacing: 1px;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  box-sizing: border-box;
  /* background-color: rgb(255, 255, 255) !important; */
  background: rgba(100, 100, 100, 0.45) url('./assets/bg-1.jpg') no-repeat
    center center fixed !important;
  -webkit-background-size: cover !important;
  -moz-background-size: cover !important;
  -o-background-size: cover !important;
  background-size: cover !important;
  background-blend-mode: darken;
  display: flex;
  flex-direction: column;
  height: 100%;
  /* background: linear-gradient(
    to bottom right,
    rgba(200, 200, 200, 0.6),
    rgba(255, 255, 255, 0.6)
  ); */
  /* background: linear-gradient(
    to bottom right,
    rgba(40, 40, 40, 0.6),
    rgba(0, 212, 255, 0.6)
  ); */
  /* background: linear-gradient(
    to bottom right,
    rgba(92, 0, 103, 0.6),
    rgba(0, 212, 255, 0.6)
  ); */
  /* background-repeat: no-repeat; */
  /* background: rgb(92, 0, 103);
  background: radial-gradient(
    circle,
    rgba(92, 0, 103, 1) 0%,
    rgba(0, 212, 255, 1) 100%
  ); */
}
.bg-layer {
  margin: 0;
  padding: 0;
  /* background: linear-gradient(
    to bottom right,
    rgba(40, 40, 40, 0.6),
    rgba(0, 212, 255, 0.6)
  ) !important; */
  background: linear-gradient(
    to bottom right,
    rgba(200, 200, 200, 0.8),
    rgba(255, 255, 255, 0.8)
  ) !important;
  min-height: 100vh !important;
  max-width: 100vw !important;
  /* position: fixed; */
  display: block;
  /* overflow-y: scroll; */
}
body::-webkit-scrollbar {
  width: 0.6em;
}
body::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}
body::-webkit-scrollbar-thumb {
  background-color: rgb(255, 248, 248);
  outline: 1px solid slategrey;
}
center {
  color: #fff;
  font-weight: bold;
  font-size: 26px;
}
</style>
