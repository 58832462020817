<script>
import Vue from 'vue'
import axios from 'axios'

const RequestDefaults = function(baseUrl) {
  if (typeof baseUrl === 'undefined') {
    baseUrl = ''
  }
  return axios.create({
    baseURL: `${baseUrl}/api`,
    method: 'get',
    timeout: 10000,
    headers: {
      'Content-Type': 'application/json'
    }
  })
}

const Services = function() {
  this.Request = RequestDefaults(process.env.VUE_APP_API_BASE_URL)
  const { Request, SetApiToken, ServicePing, Auth, Search, Download } = this
  return {
    Request,
    SetApiToken,
    ServicePing,
    Auth,
    Search,
    Download
  }
}

Services.prototype.SetApiToken = function() {
  this.Request.defaults.headers['Authorization'] =
    'Bearer ' + Vue.$cookies.get('__access-token')
}

Services.prototype.SetCustomHeader = function(key, value) {
  this.Request.defaults.headers[key] = value
}

Services.prototype.ServicePing = async function() {
  return await this.Request({
    url: '/ping'
  })
}

Services.prototype.ServicePing = async function() {
  return await this.Request({
    url: '/ping'
  })
}

Services.prototype.Auth = async function() {
  try {
    const { status } = await this.ServicePing()
    if (status !== 200) {
      throw new Error('service is not availble')
    }
    this.SetApiToken()
    return await this.Request({
      url: '/auth',
      method: 'post'
    })
  } catch ({ message }) {
    throw new Error(message)
  }
}

Services.prototype.Search = async function(
  query,
  page = 1,
  advancedSearch = {},
  sort = null
) {
  try {
    const { status } = await this.ServicePing()
    if (status !== 200) {
      throw new Error('service is not availble')
    }
    this.SetApiToken()
    let advancedQuery = ''
    for (let key in advancedSearch) {
      let valIsValid = true
      switch (key) {
        case 'fileTypes':
          valIsValid = Boolean(advancedSearch[key].length)
          break
        case 'filename':
          valIsValid = Boolean(advancedSearch[key])
          break;
        default:
          valIsValid = valIsValid.length
      }
      if (valIsValid) {
        advancedQuery += `&${key}=${advancedSearch[key]}`
      }
    }
    sort = sort || 'dateDESC'
    // console.log('SEARCH-SERVICE:', { query, page, sort, advancedQuery })
    return await this.Request({
      url: `/search/${query}/page/${page}?sort=${sort}${advancedQuery}`
    })
  } catch ({ message }) {
    throw new Error(message)
  }
}

Services.prototype.Download = async function(id, driveId) {
  try {
    const { status } = await this.ServicePing()
    if (status !== 200) {
      throw new Error('service is not availble')
    }
    this.SetApiToken()
    return await this.Request({
      url: '/download',
      method: 'post',
      data: { id, driveId }
    })
  } catch ({ message }) {
    throw new Error(message)
  }
}

export default new Services()
</script>
