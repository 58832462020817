import Vue from "vue";
import VueRouter from 'vue-router'

import Home from './routes/Home.vue'
import NotFound from './routes/NotFound.vue'

Vue.use(VueRouter);

const routes = [
  { path: '/', name: 'home', component: Home },
  { path: '*', name: 'NotFound', component: NotFound },
];

export default new VueRouter({
  mode: 'history',
  routes,
});