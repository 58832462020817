<template>
  <div>
    <Navbar />
    <SearchGuide v-if="showContent" />
    <SearchBar v-if="showContent" />
    <div v-if="!showContent" class="welcome-message">
      <span>Please sign-in using your candesic email.</span>
    </div>
  </div>
</template>

<script>
import Navbar from '../components/Navbar.vue'
import SearchBar from '../components/Search-bar.vue'
import SearchGuide from '../components/SearchGuide.vue'
import VueCookies from "vue-cookies";

export default {
  name: 'Home',
  components: {
    Navbar,
    SearchBar,
    SearchGuide
  },
  data() {
    return {
      showContent: false,
      list: []
    }
  },
  mounted() {
    this.showContent = VueCookies.get('__user-fullname')
    this.$root.$on('hasUser', (hasUser = false) => {
      this.showContent = hasUser
    })
  }
}
</script>

<style>
.welcome-message {
  margin: 40vh auto;
  text-align: center;
  color: #333;
  font-size: 14pt;
  font-weight: bold;
}
</style>
