<template>
  <div>
    <div class="search-inp-container" v-if="hasPermission">
      <form @submit="startSearch" onsubmit="javascript:return false;">
        <input
          class="search-inp"
          type="search"
          maxlength="38"
          placeholder="Search for..."
          v-model="searchQuery"
        />
        <span class="faIcons" @click="startSearch">
          <font-awesome-icon icon="fa-solid fa-magnifying-glass" size="2x" />
        </span>
      </form>
    </div>
    <div class="search-checkbox-container">
      <a
        class="advanced-search"
        @click="openAdvancedSearch"
        :class="advancedSearchIsOpen ? 'active' : ''"
      >
        <b-icon-caret-up-fill
          v-if="advancedSearchIsOpen"
        ></b-icon-caret-up-fill>
        <b-icon-caret-down-fill
          v-if="!advancedSearchIsOpen"
        ></b-icon-caret-down-fill>
        &nbsp;Advanced Search
      </a>
      <transition name="el-zoom-in-top">
        <div
          class="advanced-search-panel transition-box"
          v-show="advancedSearchIsOpen"
        >
          <ul class="advanced-search-items">
            <li>
              File names only
              <el-switch
                v-model="advancedSearch.filename"
                @change="startSearch(true)"
              ></el-switch>
            </li>
            <li>
              <!-- <el-date-picker
                v-model="advancedSearch.dateRange"
                type="daterange"
                range-separator=""
                start-placeholder="From"
                end-placeholder="To"
                @change="startSearch(true)"
              >
              </el-date-picker> -->
              <el-date-picker
                v-model="advancedSearch.startDate"
                type="date"
                size="mini"
                class="date-picker"
                align="center"
                placeholder="Start Date"
                value-format="yyyy-MM-dd"
              >
              </el-date-picker>
              <el-date-picker
                v-model="advancedSearch.endDate"
                type="date"
                size="mini"
                class="date-picker"
                align="center"
                placeholder="End Date"
                value-format="yyyy-MM-dd"
                @change="startSearch(true)"
              >
              </el-date-picker>
            </li>
            <li>
              <el-select
                v-model="advancedSearch.fileTypes"
                multiple
                placeholder="File Type"
                size="mini"
                class="advsearch-filetype"
                @change="startSearch(true)"
              >
                <el-option
                  v-for="item in advancedSearchFileTypes"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </li>
            <li>
              <el-input
                placeholder="Author"
                v-model="advancedSearch.author"
                size="mini"
                @blur="startSearch(true)"
              ></el-input>
            </li>
          </ul>
        </div>
      </transition>
    </div>
    <div class="lizard-loading" v-if="searchInProgress">{{ searchingTxt }}</div>
    <div class="lizard-results" v-if="list.length && !searchInProgress">
      <div class="row search-header">
        <div class="col-sm-7 lizard-results-text">
          Results of "{{ searchedQuery }}":
        </div>
        <div class="col-sm-5 results-static-toolbar lizard-results-sort-text">
          <ul>
            <li>
              <span
                class="faIcons faIcon-link"
                :class="templateView === 'Tile' ? 'active' : ''"
                @click="toggleView('Tile')"
              >
                <font-awesome-icon icon="fa-solid fa-border-all" size="lg" />
              </span>
              <span
                class="faIcons faIcon-link"
                :class="templateView === 'List' ? 'active' : ''"
                @click="toggleView('List')"
              >
                <font-awesome-icon icon="fa-solid fa-list" size="lg" />
              </span>
            </li>
            <li>
              <el-dropdown @command="changeFileTypes">
                <span class="el-dropdown-link">
                  Filter by {{ filterBy
                  }}<b-icon-caret-down-fill></b-icon-caret-down-fill>
                </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item
                    v-for="itm in staticFileTypes"
                    :key="itm.id"
                    :itm="itm"
                    :command="itm"
                    >{{ itm.text }}</el-dropdown-item
                  >
                  <el-dropdown-item command="def" divided
                    >All (Remove filter)</el-dropdown-item
                  >
                </el-dropdown-menu>
              </el-dropdown>
            </li>
            <li>
              <el-dropdown @command="changeSort">
                <span class="el-dropdown-link">
                  Sort by {{ sortBy
                  }}<b-icon-caret-down-fill></b-icon-caret-down-fill>
                </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item
                    v-for="itm in sortItems"
                    :key="itm.id"
                    :itm="itm"
                    :command="itm"
                    >{{ itm.text }}</el-dropdown-item
                  >
                  <el-dropdown-item command="def" divided>All</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </li>
          </ul>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12" v-if="templateView === 'List'">
          <span v-if="!filterBy"
            ><SearchResults v-for="itm in list" :key="itm.id" :itm="itm"
          /></span>
          <span v-if="filterBy"
            ><SearchResults v-for="itm in filteredList" :key="itm.id" :itm="itm"
          /></span>
        </div>
        <div class="col-lg-12" v-if="templateView === 'Tile'">
          <span v-if="!filterBy"
            ><SearchResultsNew v-for="itm in list" :key="itm.id" :itm="itm"
          /></span>
          <span v-if="filterBy"
            ><SearchResultsNew
              v-for="itm in filteredList"
              :key="itm.id"
              :itm="itm"
          /></span>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12 load-more">
          <hr />
          <span
            class="load-more-link clickable"
            v-if="!filterBy"
            @click="loadMore()"
            >{{ loadMoreText }}</span
          >
          <span class="load-more-link" v-if="filterBy">
            please remove the filter to load more results<br />
            or if you are looking for a specific file type to search<br />please
            use the advanced search filters
          </span>
          <!-- <b-pagination-nav
            :pages="pages"
            :number-of-pages="pages.length"
            v-model="page"
            @input="updatePage(page)"
            size="sm"
            align="center"
          >
            <template #page="{ page, active }">
              <b v-if="active">{{ page }}</b>
              <i v-else>{{ page }}</i>
            </template>
          </b-pagination-nav> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VueCookies from 'vue-cookies'
import Services from '../services/index.vue'
import SearchResults from './Search-results.vue'
import SearchResultsNew from './Search-results-new.vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

export default {
  name: 'SearchBar',
  components: {
    SearchResults,
    SearchResultsNew,
    FontAwesomeIcon
  },
  data() {
    return {
      showNewTemplate: true,
      templateView: 'Tile',
      advancedSearchIsOpen: false,
      sortBy: '',
      filterBy: '',
      searchInProgress: false,
      searchingTxt: 'Please wait...',
      hasPermission: false,
      page: 1,
      pages: [],
      perpage: 25,
      totalPages: 0,
      total: 0,
      list: [],
      filteredList: [],
      sortItems: [
        {
          id: 'title',
          text: 'Title'
        },
        {
          id: 'date',
          text: 'Date'
        }
      ],
      staticFileTypes: [
        {
          id: 'pdf',
          text: 'PDF'
        },
        {
          id: 'docx',
          text: 'Word'
        },
        {
          id: 'xls',
          text: 'Excel'
        },
        {
          id: 'csv',
          text: 'CSV'
        },
        {
          id: 'pptx',
          text: 'Power Point'
        },
        {
          id: 'zip',
          text: 'Compressed (zip)'
        }
      ],
      searchQuery: '',
      advancedSearch: {
        filename: false,
        startDate: '',
        endDate: '',
        fileTypes: '',
        author: ''
      },
      advancedSearchFileTypes: [
        {
          value: 'pdf',
          label: 'PDF'
        },
        {
          value: 'docx',
          label: 'Word'
        },
        {
          value: 'xls',
          label: 'Excel'
        },
        {
          value: 'csv',
          label: 'CSV'
        },
        {
          value: 'pptx',
          label: 'Power Point'
        },
        {
          value: 'zip',
          label: 'Compressed (zip)'
        }
      ],
      searchedQuery: '',
      loadMoreText: 'load more'
    }
  },
  mounted() {
    this.hasPermission = VueCookies.get('__access-token')
    this.$root.$on('hasUser', (status) => {
      this.hasPermission = status
    })
  },
  methods: {
    async startSearch(resetPage = false, sort = null, keepPrevResults = false) {
      try {
        this.filterBy = ''
        this.filteredList = []
        const searchQuery = this.searchQuery.trim()
        const prevPage =
          !resetPage && searchQuery === this.searchedQuery ? this.page : 1
        if (!keepPrevResults) this.resetList(prevPage)
        if (!searchQuery.length) {
          return false
        }
        const dateRange = []
        if (this.$moment(this.advancedSearch.startDate).isValid()) {
          dateRange[0] = this.advancedSearch.startDate
        }
        if (this.$moment(this.advancedSearch.endDate).isValid()) {
          dateRange[1] = this.advancedSearch.endDate
        } else if (dateRange.length === 1) {
          this.advancedSearch.endDate = this.$moment().format('YYYY-MM-DD')
          dateRange[1] = this.advancedSearch.endDate
        }
        if (dateRange.length) {
          this.advancedSearch.dateRange = dateRange.join(',')
        } else {
          this.advancedSearch.dateRange = ''
        }
        if (!keepPrevResults) {
          this.searchInProgress = true
          this.searchingTxt = 'Please wait...'
        }
        const results = await Services.Search(
          searchQuery,
          this.page,
          this.advancedSearch,
          sort
        )
        const { total, list } = results.data
        if (!total) {
          this.searchingTxt = 'Nothing found for this keyword'
          setTimeout(() => {
            this.searchInProgress = false
          }, 2000)
          return
        }
        if (!keepPrevResults) {
          if (this.page === 1) {
            this.countResults(total)
          } else {
            this.searchingTxt = `Page ${this.page}`
            setTimeout(() => {
              this.searchInProgress = false
            }, 1000)
          }
        }
        list.forEach((itm, idx) => {
          this.list.push({
            no: (this.page - 1) * this.perpage + (idx + 1),
            id: itm.hitId,
            driveId: itm.resource.parentReference?.driveId ?? null,
            name: itm.resource.name,
            author: itm.resource.createdBy.user.displayName,
            webUrl: itm.resource.webUrl,
            // summary: itm.summary.substr(0, 120),
            summary: itm.summary.replace(/<.+?>/g, '').trim(),
            year: itm.year,
            project: itm.project,
            private: false,
            // private: itm.private,
            createdDate: this.$moment(itm.resource.createdDateTime).format(
              'MMMM, Do YYYY'
            )
          })
        })
        if (!keepPrevResults) {
          let totalPages = Math.ceil(total / this.perpage)
          for (let p = 0; p < totalPages; p++) {
            this.pages.push({
              text: p + 1
            })
          }
          this.searchedQuery = searchQuery
        }
        if (total > this.perpage) {
          while (this.list.length < this.perpage) {
            await this.loadMore()
          }
        }
        // if (this.filterBy) {
        //   const filterBy = this.staticFileTypes.find((itm) => {
        //     return itm.text === this.filterBy
        //   })
        //   await this.changeFileTypes(filterBy)
        // }
        // console.log({ results: results.data })
      } catch (Exception) {
        this.searchInProgress = false
        this.searchingTxt = 'Check your connection please.'
      }
    },
    countResults(total) {
      let timeout = setInterval(() => {
        if (this.total < total) {
          if (total < 100) {
            this.total += 1
          } else if (total < 5000) {
            this.total += 10
          } else {
            this.total += 100
          }
          this.searchingTxt = this.total + ' results found.'
        } else {
          this.total = total
          this.searchingTxt = this.total + ' results found.'
          clearInterval(timeout)
          setTimeout(() => {
            this.searchInProgress = false
          }, 2000)
        }
      }, 1)
    },
    async updatePage() {
      await this.startSearch()
    },
    openAdvancedSearch() {
      this.advancedSearchIsOpen = !this.advancedSearchIsOpen
    },
    async changeSort(cmd = 'def') {
      if (cmd === 'def') {
        if (this.sortBy !== '') {
          this.sortBy = ''
          await this.startSearch(true, null)
        }
      } else {
        const { id, text } = cmd
        const descReg = new RegExp('.+?(DESC)', 'i')
        this.sortBy =
          this.sortBy === ` ${text} (DESC) ` ? ` ${text} ` : ` ${text} (DESC) `
        const sort = descReg.test(this.sortBy) ? `${id}DESC` : id
        await this.startSearch(true, sort)
      }
    },
    async changeFileTypes(cmd = 'def') {
      if (cmd === 'def') {
        if (this.filterBy !== '') {
          this.filterBy = ''
          this.filteredList = []
        }
      } else {
        const { id, text } = cmd
        this.filterBy = text
        const filterByReg = new RegExp('.+?\\.' + id + '$', 'i')
        // console.log({ filterBy })
        this.filteredList = this.list
        this.filteredList = this.filteredList.filter((itm) => {
          return filterByReg.test(itm.name)
        })
      }
    },
    resetList(page = 1) {
      this.page = page
      this.pages = []
      this.perpage = 25
      this.totalPages = 0
      this.total = 0
      this.list = []
      this.loadMoreText = 'load more'
    },
    toggleView(view = 'tile') {
      this.templateView = view
    },
    async loadMore() {
      const currentListLength = this.list.length
      if (this.page < this.pages.length) {
        this.loadMoreText = 'please wait...'
        this.page++
        await this.startSearch(false, null, true)
      }
      this.loadMoreText =
        currentListLength === this.list.length
          ? 'all results loaded'
          : 'load more'
    }
  }
}
</script>

<style>
.faIcons {
  color: #999;
}

.faIcons:hover {
  cursor: pointer;
  color: #666;
}

.faIcon-link {
  margin: 0 4px;
  color: #999;
}

.faIcon-link.active {
  color: #fff;
}

.search-header {
  padding: 10px;
}

.lizard-loading {
  animation: 1s appear;
  max-width: 50vw;
  margin: 0 auto;
  text-align: center;
  font-family: 'Source Sans Pro', -apple-system, BlinkMacSystemFont, 'Segoe UI',
    Roboto, 'Helvetica Neue', Arial, sans-serif;
  font-size: 40px;
  color: #fff;
  word-spacing: 1px;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-weight: 300;
}
.lizard-results-text {
  animation: 1s appearAndMove;
  margin: 0 auto;
  text-align: left;
  font-family: 'Source Sans Pro', -apple-system, BlinkMacSystemFont, 'Segoe UI',
    Roboto, 'Helvetica Neue', Arial, sans-serif;
  font-size: 20px;
  color: #fff;
  word-spacing: 1px;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-weight: 600;
}
.lizard-results {
  max-width: 90vw;
  margin: 20px auto;
  overflow: hidden !important;
}

.lizard-results-view-switcher {
  animation: 1s appearAndMove;
  margin: 0 auto;
  text-align: center;
  font-family: 'Source Sans Pro', -apple-system, BlinkMacSystemFont, 'Segoe UI',
    Roboto, 'Helvetica Neue', Arial, sans-serif;
  font-size: 14px !important;
  color: #999;
  word-spacing: 1px;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-weight: 600;
  line-height: 26px !important;
  /* background-color: red; */
}

.results-static-toolbar ul {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.lizard-results-sort-text {
  animation: 1s appearAndMove;
  margin: 0 auto;
  text-align: right;
  font-family: 'Source Sans Pro', -apple-system, BlinkMacSystemFont, 'Segoe UI',
    Roboto, 'Helvetica Neue', Arial, sans-serif;
  font-size: 18px;
  color: #999;
  word-spacing: 1px;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-weight: 600;
  /* background-color: red; */
}

.advanced-search-panel {
  margin: 0 auto;
  padding: 10px;
  max-width: 100%;
  min-height: 40px;
  border: 0;
  border-radius: 5px;
  margin-bottom: 5px;
  text-align: center;
  font-size: 10pt;
}

.search-inp-container {
  animation: 1s appear;
  border: 2px solid #999;
  padding: 7px 5px 5px 20px;
  margin: 40px auto 0 auto;
  border-radius: 5px;
  background-color: rgba(255, 255, 255, 0.7);
}

.search-checkbox-container {
  animation: 1s appear;
  color: #fff;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  margin: 10px auto;
}

.search-ico {
  color: #666;
  font-size: 36px;
  font-weight: bold;
  margin-top: 3px;
}

.search-ico:hover {
  color: #333;
  cursor: pointer;
}

.search-inp {
  width: 90%;
  border: none;
  margin: 0;
  padding: 0;
  font-size: 24px;
  letter-spacing: 0.5px;
  line-height: 50px !important;
  background: transparent;
  font-weight: 400;
  color: #666 !important;
  font-family: 'Quicksand', 'Source Sans Pro', -apple-system, BlinkMacSystemFont,
    'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
}

.search-inp:focus {
  outline: none;
}

.search-inp:active {
  outline: none;
}

.date-picker {
  max-width: 130px;
  margin: 0 1px;
}

.namesOnlyCheckBox {
  color: #fff !important;
  font-weight: bold;
}

.advanced-search {
  cursor: pointer;
  color: #666;
}

.advanced-search:hover {
  color: #333;
  text-decoration: none;
}

.advanced-search.active {
  color: #fff;
}

.advanced-search-items {
  list-style: none;
  margin: 0;
  padding: 0;
}

.advanced-search-items li {
  display: inline-block;
  margin: 0 16px;
}

.advsearch-filetype {
  width: 100%;
  background-color: rgba(255, 255, 255, 0.1) !important;
}

.el-dropdown-link {
  cursor: pointer;
  color: #999;
}
.el-icon-arrow-down {
  font-size: 12px;
}

.load-more {
  font-weight: bold !important;
  color: #666;
  margin: 0 auto;
  font-size: 14pt;
  font-variant: small-caps;
  text-align: center;
}

.load-more-link.clickable:hover {
  color: #333;
  text-decoration: underline;
  cursor: pointer;
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #999;
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #999;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #999;
}

@keyframes appear {
  0% {
    opacity: 0;
  }
}

@keyframes appearAndMove {
  0% {
    opacity: 0;
    margin-left: -20px;
    color: #999;
  }
  100% {
    opacity: 1;
    margin-left: 0px;
    color: #fff;
  }
}
</style>
