<template>
  <div class="search-result-box-new" @click="GoToFile">
    <div class="search-result-title">{{ itm.name }}</div>
    <div class="search-result-author">
      <ul class="itm-details">
        <li>{{ itm.author || 'Unknown' }}</li>
        <li>{{ itm.createdDate }}</li>
        <li v-if="itm.year">
          <span title="Project">{{ itm.project }}</span>
        </li>
      </ul>
    </div>
    <!-- <div class="search-result-url">
      <b-badge
        v-for="loc in ParseURL(itm.webUrl)"
        :key="loc"
        :itm="loc"
        variant="warning"
        class="loc-badge"
        >{{ loc }}</b-badge
      >
    </div> -->
    <!-- <div class="search-result-project" v-if="itm.year"><span title="Year">{{ itm.year }}</span> - <span title="Project">{{ itm.project }}</span></div> -->
    <!-- <div class="search-result-summary" v-if="itm.summary">
      {{ itm.summary }}
    </div> -->
    <div
      v-if="!itm.private && showDlBtn"
      class="search-result-download"
      :class="dlError ? 'dl-err' : ''"
      title="Download"
      @click="DownloadFile(itm)"
    >
      {{ dlText }}
    </div>
  </div>
</template>

<script>
import Services from '../services/index.vue'

export default {
  name: 'SearchResultsNew',
  props: ['itm'],
  data: () => {
    return {
      showDlBtn: true,
      dlText: 'download',
      dlError: false
    }
  },
  methods: {
    ParseURL(url) {
      const parsed = new URL(url)
      let result = parsed.pathname.replace(/%20/g, ' ').split('/')
      const emailPattern = new RegExp('^.+?_candesic_com$', 'ig')
      result = result.map((itm) => {
        switch (itm) {
          case 'Microsoft Teams Chat Files':
            itm = 'teams/chat'
            break
          case 'Documents':
          case 'Forms':
          case 'DispForm.aspx':
            itm = null
            break
        }
        if (emailPattern.test(itm)) {
          itm = itm.split('_')[0] + '@candesic.com'
        }
        return itm
      })
      result = result.filter((itm) => itm != null)
      return result
    },
    async GoToFile() {
      // console.log(JSON.stringify(this.itm, null, '\t'))
      return await this.DownloadFile(this.itm)
      //window.open(this.itm.webUrl)
    },
    async DownloadFile({ id, driveId }) {
      if (this.dlText !== 'download') {
        return
      }
      this.dlText = 'please wait...'
      // console.log('feature disabled.')
      const { data } = await Services.Download(id, driveId)
      if (!data.err) {
        location.replace(data.path)
        this.dlText = 'download'
      } else {
        this.dlError = true
        this.dlText = data.err
        setTimeout(() => {
          this.dlText = 'download'
          this.dlError = false
        }, 4000)
      }
    }
  }
}
</script>

<style>
.dl-err {
  background-color: #ff0000 !important;
}
.search-result-box-new {
  animation: 0.5s appearAndMove2;
  text-align: left;
  margin: 1%;
  background-color: rgba(255, 255, 255, 0.6);
  border-radius: 6px;
  border: 2px solid #999;
  cursor: pointer;
  width: 23% !important;
  height: 200px !important;
  display: inline-block;
  position: relative;
  vertical-align: top;
  overflow: hidden;
}
.search-result-box-new:hover {
  background-color: rgba(255, 255, 255, 0.3);
  /* cursor: pointer; */
}
.itm-details {
  margin: 10px;
  padding: 0;
  list-style: none;
}
.loc-badge {
  margin: 0 2px;
  text-transform: uppercase;
  font-weight: bold;
}
.search-result-title {
  font-size: 10pt !important;
  font-weight: bold;
  color: #333;
  background-color: rgba(255, 255, 255, 0.6);
  padding: 5px;
}
.search-result-url {
  font-size: 10pt !important;
  font-weight: bold;
  color: #ccc;
  margin-bottom: 10px;
}
.search-result-author {
  font-size: 10pt !important;
  color: #666;
  font-weight: bold;
  position: relative;
  display: inline-block;
}
.search-result-project {
  font-size: 10pt !important;
  color: #666;
  font-weight: bold;
}
.search-result-summary {
  font-size: 8pt !important;
  color: #666;
  font-weight: bold;
}
.search-result-download {
  position: absolute;
  display: block;
  color: #fff;
  font-weight: bold;
  background-color: darkcyan;
  padding: 2px 6px;
  border-radius: 3px;
  width: 100%;
  text-align: center;
  bottom: 0 !important;
  font-variant: small-caps;
  letter-spacing: 0.1rem;
}
.search-result-download:hover {
  cursor: pointer;
  color: #333;
  background-color: cyan;
}
@keyframes appearAndMove2 {
  0% {
    opacity: 0;
    margin-top: -10px;
  }
  100% {
    opacity: 1;
    margin-top: 0px;
  }
}
</style>
