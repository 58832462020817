export default function(Vuex) {
  return new Vuex.Store({
    state: {
      packageName: process.env.PACKAGE_NAME,
      packageVersion: process.env.PACKAGE_VERSION,
      appEnv: process.env.APP_ENV,
    },
    getters: {
      appName: (state) => {
        return state.packageName;
      },
      appVersion: (state) => {
        return state.packageVersion;
      },
      appEnv: (state) => {
        return state.appEnv;
      },
    },
  });
}
