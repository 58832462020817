<template>
  <div>
    <h1>
      <br />&nbsp;&nbsp;<br />
      <b-badge variant="warning">{{ message }}!</b-badge>
    </h1>
  </div>
</template>

<script>
export default {
  name: 'NotFound',
  data() {
    return {
      message: 'Error 404: Not Found',
    }
  }
}
</script>