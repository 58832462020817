import Vue from 'vue'
import Vuex from 'vuex'
import VueCookies from 'vue-cookies'
import Router from './router'
import App from './App.vue'
import Element from 'element-ui'
import locale from 'element-ui/lib/locale/lang/en'
import Store from './store/index.js'
import Moment from 'vue-moment'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faMagnifyingGlass,
  faTableList,
  faList,
  faDownload,
  faBorderAll
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

library.add(faMagnifyingGlass, faTableList, faList, faDownload, faBorderAll)

Vue.use(Vuex)
Vue.use(VueCookies)
Vue.use(Moment)
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(FontAwesomeIcon)
Vue.use(Element, { locale, size: 'small', zIndex: 3000 })

Vue.$cookies.config('8h')

Vue.config.productionTip = false

new Vue({
  router: Router,
  store: Store(Vuex),
  render: (h) => h(App)
}).$mount('#app')
