<template>
  <div>
    <div class="info-ico-txt" v-b-modal.info-modal>
      <b-icon-info-circle
        class="info-ico"
      ></b-icon-info-circle
      >Advanced Search Syntax Guide
    </div>
    <b-modal id="info-modal" hide-footer title="Advanced Search Syntax Guide">
      <b-table striped hover :items="infoItems"></b-table>
    </b-modal>
  </div>
</template>

<script>
import VueCookies from 'vue-cookies'

export default {
  name: 'SearchGuide',
  data() {
    return {
      hasPermission: false,
      infoItems: [
        {
          Operator: 'Wildcards',
          Explanation:
            'You can use the wildcard operator (*) for prefix matching. This example will return results for print, printer, and printing.',
          Examples: 'Print*'
        },
        {
          Operator: 'Phrases',
          Explanation:
            'Use the phrase format if you know two or more words. Surround the phrase with double quotation marks.',
          Examples: '"Graduate Research School"'
        },
        {
          Operator: 'File Type',
          Explanation:
            'To bring back documents of a specific file type use the filetype operator.',
          Examples: 'filetype:docx'
        },
        {
          Operator: 'File Name',
          Explanation:
            'If you know the name of the document you can use the filename operator',
          Examples: 'filename:policy.docx'
        },
        {
          Operator: 'Title',
          Explanation:
            'As well as a file name every document should have a readable title.',
          Examples: 'title:"Student Engagement Report"'
        },
        {
          Operator: 'Author',
          Explanation:
            'To return documents written by a particular person use the author operator. The format is "surname, first name"',
          Examples: 'author:"bloggs, joe"'
        },
        {
          Operator: 'Path',
          Explanation:
            'To restrict your search to a particular area use the path operator',
          Examples: 'path:http://extra.tees.ac.uk'
        },
        {
          Operator: 'Exclude content',
          Explanation:
            'Include a minus sign to return results that do not contain an expression.',
          Examples: 'database -"policy"'
        },
        {
          Operator: 'AND',
          Explanation:
            'Use AND when the results must contain all the query expressions.',
          Examples: 'Service AND management'
        },
        {
          Operator: 'Combining',
          Explanation:
            'All of the above operators can be combined to provide more complex search queries.',
          Examples:
            'title:"network" AND filetype:docx AND path:https://extra.tees.ac.uk'
        }
      ]
    }
  },
  mounted() {
    this.hasPermission = VueCookies.get('__access-token')
    this.$root.$on('hasUser', (status) => {
      this.hasPermission = status
    })
  },
  methods: {}
}
</script>

<style>
#info-modal .modal-content {
  width: 55vw !important;
  margin-left: -5vw !important;
}

.info-ico {
  color: #fff;
  font-size: 14px;
  font-weight: bold;
  margin-right: 5px;
}

.info-ico-txt {
  float: right;
  color: #fff;
  font-size: 12px;
  font-weight: bold;
  margin: 10px 0;
}

.info-ico-txt:hover {
  color: #ccc;
  cursor: pointer;
}
</style>
