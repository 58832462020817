<template>
  <div class="lizard-navbar">
    <b-navbar toggleable="lg" type="light">
      <b-navbar-brand>
        <Logo />
      </b-navbar-brand>
      <Azure class="lizard-navbar-azure" />
    </b-navbar>
  </div>
</template>

<script>
import Logo from './Logo.vue'
import Azure from './Azure.vue'

export default {
  name: 'Navbar',
  components: {
    Logo,
    Azure
  }
}
</script>

<style scoped>
.lizard-navbar {
  background-color: rgba(40, 40, 40, 0.5) !important;
  border-radius: 0 0 5px 5px;
  padding-bottom: 10px;
  /* border: 1px solid #666; */
  border-top-width: 0 !important;
}
.lizard-navbar-azure {
  position: absolute;
  right: 2% !important;
}
@keyframes mymove {
  from {
    background: rgba(40, 40, 40, 0.4);
  }
  to {
    background: rgba(40, 40, 40, 0.9);
  }
}
</style>
